/* website: 3089-planetkia
 * created at 2020-05-25 15:47 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";
.widget-sr{

  .section-showroom-header__progressBar-stage.selected{
    .progressBar-stage__active-effect{
      background-color: #05141f !important;
      fill:#05141f !important;
    }
  }
  .picto-showroom{
    &.undefined{
      background:#05141f !important;
    }
  }
  .financing-section.tile-payment-info{
    .cashdown{
      border-top: 4px solid #05141f !important;
    }
  }
  .payment-summary-section {
    .listing-tile-all-price{
      border-top: 4px solid #05141f !important;
    }
  }
}